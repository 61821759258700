<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Новый аккаунт"
        create
        url-update="/user/update/:id"
        url-back="/user"
    >
        <user-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import UserForm from '@/components/forms/UserForm.vue'
export default {
    name: 'UserCreate',
    components: { FormView, UserForm }
}
</script>